<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Paso 4 de 5
            </template>
            <template v-slot:subtitle>
                Datos Académicos
            </template>
            <template v-slot:content>                
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
						<label for="name">Nombre del plantel donde cursó el último año de educación media</label>
						<InputText v-model="academico.plantel" required="true"/>
                        <small v-show="validationErrors.plantel && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-10">
						<label for="name">Rama de la educación media que cursó</label>
						<InputText v-model="academico.rama" required="true"/>
                        <small v-show="validationErrors.rama && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-2">
                        <label>Año de Graduación</label>
                        <InputMask v-model="academico.ano" mask="9999" />
                        <small v-show="validationErrors.ano && submitted" style="color: red;">Campo obligatorio</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label>Título de Bachiller o Constancia de Culminación</label>
                        <FileUpload chooseLabel="Subir JPG" uploadLabel="Cargar" mode="basic" :name="token" :url="anexos+'/doc'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(0)" :auto="true"/>
                        <small v-show="validationErrors.doc_titulo && submitted" style="color: red;">Debe cargar la Imagen</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
						<img style="width: 100px;" :src="anexos+'/ver/'+academico.doc_titulo" v-if="academico.doc_titulo"/>
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label>Notas Certificadas de Bachillerato</label>
                        <FileUpload chooseLabel="Subir JPG - Página 1" uploadLabel="Cargar" mode="basic" :name="token" :url="anexos+'/doc'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(1)" :auto="true"/>
                        <small v-show="validationErrors.doc_notas1 && submitted" style="color: red;">Debe cargar la Imagen</small>
                        <br><FileUpload chooseLabel="Subir JPG - Página 2" uploadLabel="Cargar" mode="basic" :name="token" :url="anexos+'/doc'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(2)" :auto="true"/>
                        <br><FileUpload chooseLabel="Subir JPG - Página 3" uploadLabel="Cargar" mode="basic" :name="token" :url="anexos+'/doc'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(3)" :auto="true"/>
                    </div>
                    <div class="p-field p-col-12 p-md-3">
						<img style="width: 100px;" :src="anexos+'/ver/'+academico.doc_notas1" v-if="academico.doc_notas1"/>
                    </div>
                    <div class="p-field p-col-12 p-md-3">
						<img style="width: 100px;" :src="anexos+'/ver/'+academico.doc_notas2" v-if="academico.doc_notas2"/>
                    </div>
                    <div class="p-field p-col-12 p-md-3">
						<img style="width: 100px;" :src="anexos+'/ver/'+academico.doc_notas3" v-if="academico.doc_notas3"/>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Siguiente" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
//import API from "@/service/API";
import Token from "uuid-token-generator";
//const Consulta = new API('Preinscripcion','Procesar');

export default {
    data () {
        return {
            submitted: false,
            validationErrors: {},
            academico: {
                plantel: null,
                rama: null,
                ano: null,
                doc_titulo: null,
                doc_notas1: null,
                doc_notas2: null,
                doc_notas3: null,
            },            
            anexos: null,  
        }
    },
	created() {
        this.anexos = process.env.VUE_APP_ANEXOS;
        //this.url = process.env.VUE_APP_API;
        const tokgen = new Token();
        this.token = tokgen.generate();
        this.$store.commit('Loading');
	},
    methods: {
		myUploader(caso) {
            if (caso==0){this.academico.doc_titulo = this.token;}
            if (caso==1){this.academico.doc_notas1 = this.token;}
            if (caso==2){this.academico.doc_notas2 = this.token;}
            if (caso==3){this.academico.doc_notas3 = this.token;}   
            const tokgen = new Token();
            this.token = tokgen.generate();
            /*
			Consulta.Procesar('ImgDoc',{
				img: this.token,
			}).then(response => {
                if(response.result){
                    if (caso==0){this.academico.doc_titulo = this.token;}
                    if (caso==1){this.academico.doc_notas1 = this.token;}
                    if (caso==2){this.academico.doc_notas2 = this.token;}
                    if (caso==3){this.academico.doc_notas3 = this.token;}                    
                }
                const tokgen = new Token();
                this.token = tokgen.generate();
				this.$store.commit('Loading');
			});
            */
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                this.$emit('next-page', {formData: {academico: this.academico}, pageIndex: 3});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 3});
        },
        validateForm() {
            if (this.academico.plantel==null)
                this.validationErrors['plantel'] = true;
            else
                delete this.validationErrors['plantel'];
            if (this.academico.rama==null)
                this.validationErrors['rama'] = true;
            else
                delete this.validationErrors['rama'];
            if (!this.academico.ano)
                this.validationErrors['ano'] = true;
            else
                delete this.validationErrors['ano'];
            if (this.academico.doc_titulo==null)
                this.validationErrors['doc_titulo'] = true;
            else
                delete this.validationErrors['doc_titulo'];
            if (this.academico.doc_notas1==null)
                this.validationErrors['doc_notas1'] = true;
            else
                delete this.validationErrors['doc_notas1'];
            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>